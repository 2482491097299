import BaseLink, { LinkProps } from "next/link";
import getConfig from "next/config";
import { useEffect, useMemo } from "react";
const { publicRuntimeConfig } = getConfig();
import { UrlObject } from "url";
import styles from "./link.module.css";
import classNames from "classnames";
import { isUrl } from "@/utils/validate";
import { useRouter } from "next/router";

type Url = string | UrlObject;
type BaseLinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof LinkProps
> &
  LinkProps & {
    children?: React.ReactNode;
    onBeforeNavigate?: (path: string) => void; // 新增的回调属性
  } & React.RefAttributes<HTMLAnchorElement>;
export default function Link({ children, href, onBeforeNavigate, ...props }: BaseLinkProps) {
  const router = useRouter();

  const linkHref = useMemo(() => {
    let link: Url = "";
    const assetPrefix = publicRuntimeConfig.ASSET_PREFIX === '/' ? '' : publicRuntimeConfig.ASSET_PREFIX;
    if (typeof href === "string") {
      // 判断href是否是以http或https开头的
      link = `${!isUrl(href) ? assetPrefix : ""}${href}`;
    } else if (href?.pathname) {
      link = {
        ...href,
        pathname: `${!isUrl(href?.pathname) ? assetPrefix : ""}${href.pathname}`,
      };
    }

    return link;
  }, [href]);

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      if (onBeforeNavigate) {
        onBeforeNavigate(url);
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [onBeforeNavigate, router.events]);

  return (
    <BaseLink
      {...props}
      href={linkHref}
      className={classNames(styles.base_link, props.className)}
    >
      {children}
    </BaseLink>
  );

}